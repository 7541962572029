import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const phoneNumber = "923287787787";

function BookAppointmentModal({ isOpen, onClose }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState("");

    const generateTimeSlots = () => {
        const timeSlots = [];
        let currentHour = 9;
        let currentPeriod = "AM";

        while (!(currentHour === 2 && currentPeriod === "AM")) {
            const endHour = currentHour === 12 ? 1 : currentHour + 1;
            const timeSlot = `${currentHour}:00 ${currentPeriod} - ${endHour}:00 ${currentPeriod}`;
            timeSlots.push(timeSlot);

            if (currentHour === 11) currentPeriod = currentPeriod === "AM" ? "PM" : "AM";
            currentHour = currentHour === 12 ? 1 : currentHour + 1;
        }

        return timeSlots;
    };
    const timeSlots = generateTimeSlots();

    const handleWhatsAppMessage = () => {
        if (!selectedDate || !selectedTime) return;
        const formattedDate = selectedDate.toLocaleDateString();
        const message = `Hello, I would like to book an appointment on ${formattedDate} at ${selectedTime}.`;
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, "_blank");
    };

    return (
        isOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-6 w-80">
                    <h2 className="text-xl font-bold mb-4">Select Date and Time</h2>

                    <div className="mb-4">
                        <label className="block mb-2">Select a Date:</label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            minDate={new Date()}
                            className="w-full p-2 border rounded"
                            dateFormat="MMMM d, yyyy"
                        />
                    </div>

                    <label className="block mb-2">Select a Time Slot:</label>
                    <div className="overflow-y-auto max-h-40 border rounded-md p-2">
                        <ul className="space-y-2">
                            {timeSlots.map((slot) => (
                                <li key={slot}>
                                    <button
                                        className={`w-full p-2 rounded-md transition duration-200 ease-in-out ${selectedTime === slot
                                            ? "bg-[#8d59dc] text-white"
                                            : "bg-gray-200 hover:bg-gray-300"
                                            }`}
                                        onClick={() => setSelectedTime(slot)}
                                    >
                                        {slot}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-between mt-3">
                        <button
                            className="bg-red-500 text-white py-2 px-4 rounded"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-[#8d59dc] text-white py-2 px-4 rounded"
                            onClick={handleWhatsAppMessage}
                            disabled={!selectedDate || !selectedTime}
                        >
                            Book
                        </button>
                    </div>
                </div>
            </div>
        )
    );
}

export default BookAppointmentModal;
