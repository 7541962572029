import React from "react";

export default function PrivacyPolicy() {
    return (
        <div className="mx-10 my-20 lg:mx-40 xl:mx-60 text-gray-800">
            <h1 className="text-3xl font-bold">Privacy Policy</h1>
            <p className="mt-4">
                This Privacy Policy explains how Gents Saloon collects, uses, discloses, and protects your personal information when you use our services or visit our website. By using our services, you agree to the terms outlined in this policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6">1. Information We Collect</h2>
            <p className="mt-2">
                We collect information that you provide to us directly, such as when you make an appointment, contact us, or sign up for our newsletter. This information may include your name, email address, phone number, and payment details.
            </p>

            <h2 className="text-2xl font-semibold mt-6">2. How We Use Your Information</h2>
            <p className="mt-2">
                The information we collect is used to provide, maintain, and improve our services. We may use your information to process bookings, send notifications or promotions, respond to your inquiries, and personalize your experience with us.
            </p>

            <h2 className="text-2xl font-semibold mt-6">3. Sharing of Information</h2>
            <p className="mt-2">
                Gents Saloon does not sell, trade, or rent your personal information to others. We may share your information with trusted service providers who assist us in operating our business, conducting our affairs, or serving our users, so long as they agree to keep this information confidential.
            </p>

            <h2 className="text-2xl font-semibold mt-6">4. Data Security</h2>
            <p className="mt-2">
                We implement security measures to protect your personal information. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.
            </p>

            <h2 className="text-2xl font-semibold mt-6">5. Your Rights</h2>
            <p className="mt-2">
                You have the right to access, update, or delete your personal information. To exercise these rights, please contact us at <a href="mailto:INFO@GENTSSALOON.PK" className="text-[#8d59dc] hover:underline">INFO@GENTSSALOON.PK</a>. We will respond to your request in a timely manner.
            </p>

            <h2 className="text-2xl font-semibold mt-6">6. Cookies and Tracking</h2>
            <p className="mt-2">
                Our website may use cookies to enhance your experience. Cookies are small data files stored on your device that help us understand how you use our site. You can disable cookies in your browser settings, but this may limit certain features.
            </p>

            <h2 className="text-2xl font-semibold mt-6">7. Third-Party Links</h2>
            <p className="mt-2">
                Our website may contain links to third-party sites. We are not responsible for the privacy practices of these sites. We encourage you to read their privacy policies before providing any personal information.
            </p>

            <h2 className="text-2xl font-semibold mt-6">8. Changes to This Policy</h2>
            <p className="mt-2">
                We reserve the right to update our Privacy Policy as needed. Changes will be posted on this page, and the "Last Updated" date will reflect the latest revision. Continued use of our services indicates acceptance of the updated policy.
            </p>

            <p className="mt-8">
                If you have any questions about this Privacy Policy, please contact us at <a href="mailto:INFO@GENTSSALOON.PK" className="text-[#8d59dc] hover:underline">INFO@GENTSSALOON.PK</a>.
            </p>
        </div>
    );
}
