import React, { useEffect, useState } from "react";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";
import BookAppointmentModal from "../components/BookAppointmentModal";

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 500,
      delay: 200,
      once: true,
    });
  }, []);

  const services = [
    {
      title: "Haircuts",
      description: "Precision haircuts tailored to your unique style. Our skilled barbers keep up with the latest trends.",
      img: '/img/haircutting.jpg',
    },
    {
      title: "Beard Grooming",
      description: "Expert beard shaping and trimming to keep you looking sharp. Includes a hot towel treatment for relaxation.",
      img: '/img/bread.avif',
    },
    {
      title: "Shaving",
      description: "Classic straight razor shaves for a smooth, comfortable finish. Experience the art of traditional shaving.",
      img: '/img/hairdresser.webp',
    },
    {
      title: "Facials",
      description: "Refreshing facial treatments designed to cleanse and rejuvenate your skin. Perfect for all skin types.",
      img: '/img/facial.jpg',
    },
    {
      title: "Coloring",
      description: "Professional hair coloring services to help you achieve your desired look. From subtle highlights to bold colors.",
      img: '/img/hairstyling.webp',
    },
  ];

  return (
    <>
      <div className=" h-[70vh] bg-cover bg-no-repeat bg-center flex flex-col" style={{
        backgroundImage: "linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.6) 100%), url('img/interior.jpg')"
      }}>
        <div
          id="home"
          className="w-screen h-screen flex justify-center relative max-[800px]:top-[10%] lg:-top-[15%]"
        >
          <div className="flex flex-col text-center min-[800px]:text-left min-[800px]:flex-row justify-center items-center w-[90vw] m-auto pt-8">
            <div className="flex flex-col items-center mx-10 md:mx-0 min-[800px]:items-start md:w-full lg:w-1/2 relative md:z-10">
              <div className="flex items-center space-x-2 text-xs lg:text-sm bg-white rounded-3xl p-1 md:p-2">
                <div className="bg-[#ed696a] h-4 w-4 rounded-full"></div>
                <p>A Salon That Connects You to Beauty</p>
              </div>
              <h1 className="text-[#ed696a] py-2 text-xl lg:text-5xl font-medium">
                GENTS<span className="text-[#8d59dc]"> SALOON</span>
              </h1>
              <h5 className="pb-8 xl:font-semibold text-xs sm:text-sm 2xl:text-lg ">
                <Typewriter options={{
                  strings: ["Available Coffee and Soft Drinks.", "Vintage Charm."],
                  autoStart: true,
                  loop: true,
                }} />
                <br />
                Welcome to the Gents Saloon, where we offer the finest handcrafted cocktails, a cozy vintage atmosphere, and live entertainment that brings the spirit of the Wild West to life.
              </h5>
              <button onClick={() => setIsModalOpen(true)} className="bg-[#8d59dc] text-sm md:text-base py-1 px-4 rounded-[20px] font-bold transition-transform duration-200 ease-in-out hover:scale-105">
                Book an Appointment
              </button>
            </div>

            <div className="relative min-[800px]:pl-20 float z-10">
              <img
                className="h-40 sm:h-52 md:h-full"
                src="/img/logo.png"
                alt="3MetaD"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mx-10 mt-20 flex flex-col justify-center" id="services">
        <h1 className="text-3xl font-bold text-center">Our Services</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-4">
              <img src={service.img} alt={service.title} className="rounded-lg h-40 w-full object-cover" />
              <h2 className="text-xl font-bold mt-4">{service.title}</h2>
              <p className="mt-2">{service.description}</p>
              <a href={`/services`} className="mt-4 inline-block bg-[#8d59dc] text-white py-2 px-4 rounded transition hover:bg-[#7b4bc5]">
                Learn More
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* About Us Section */}
      <div className="mx-10 mt-20 lg:mx-40 xl:mx-60 flex flex-col justify-center" id="about">
        <h1 className="block py-5 text-3xl font-bold text-center">About Us</h1>
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="flex sm:w-2/5 place-content-center">
            <img className="h-auto w-full" src="/img/logo.png" alt="About Us" />
          </div>
          <div className="relative sm:w-1/2 bg-[#ffffff26] rounded-2xl backdrop-blur-[5px] p-4">
            <h4 className="text-lg font-bold">
              Welcome to Gents Saloon! We are dedicated to providing the ultimate grooming experience for modern gentlemen. Our team is passionate about delivering top-notch services in a stylish and welcoming atmosphere.
            </h4>
            <p className="mt-4">
              At Gents Saloon, we prioritize quality and customer satisfaction. Our professional staff is trained in the latest trends and techniques to ensure you leave feeling refreshed and confident.
            </p>
            <p className="mt-4">
              Join us for a unique blend of relaxation and sophistication, where every detail is crafted to enhance your experience.
            </p>
          </div>
        </div>
      </div>
      <div id="contact">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3399.035439947656!2d74.3624999!3d31.578076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191b00310207e1%3A0x2b0a60176d20979c!2sGENTS%20SALOON!5e0!3m2!1sen!2s!4v1729972580348!5m2!1sen!2s" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

        <div className="bg-black px-10 py-20 lg:px-40 xl:px-60 flex flex-col justify-center">
          <h1 className="text-[#ed696a] py-2 text-xl lg:text-5xl font-medium">
            GENTS<span className="text-[#8d59dc]"> SALOON</span>
          </h1>

          {/* Quick Links */}
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6 text-center md:text-left">
            <div>
              <h2 className="text-lg font-semibold text-white">Quick Links</h2>
              <ul className="mt-4 space-y-2 text-[#8d59dc]">
                <li><a href="#home" className="hover:underline">Home</a></li>
                <li><a href="#services" className="hover:underline">Services</a></li>
                <li><a href="#about" className="hover:underline">About Us</a></li>
                <li><a href="#contact" className="hover:underline">Contact</a></li>
                <li><Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link></li>
                <li><Link to="/terms-conditions" className="hover:underline">Terms & Conditions</Link></li>
              </ul>
            </div>

            {/* Contact Information */}
            <div>
              <h2 className="text-lg font-semibold text-white">Contact Us</h2>
              <p className="mt-4 text-gray-400">
                {/* 123 Barber St.<br />
                Los Angeles, CA 90001<br /> */}
                Phone: <a href="tel:04236859024" className="text-[#8d59dc] hover:underline">042 36859024</a><br />
                Email: <a href="mailto:INFO@GENTSSALOON.PK" className="text-[#8d59dc] hover:underline">INFO@GENTSSALOON.PK</a>
              </p>
            </div>

            {/* Social Media */}
            {/* <div>
              <h2 className="text-lg font-semibold text-white">Follow Us</h2>
              <div className="mt-4 flex justify-center md:justify-start space-x-4">
                <a href="https://instagram.com" className="text-[#8d59dc] hover:text-white transition"><BsInstagram size={24} /></a>
                <a href="https://facebook.com" className="text-[#8d59dc] hover:text-white transition"><FaFacebookF size={24} /></a>
                <a href="https://tiktok.com" className="text-[#8d59dc] hover:text-white transition"><FaTiktok size={24} /></a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <BookAppointmentModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}
