import React from "react";

export default function Services() {
  const hairServices = [
    { title: "Cut & Blowdry", stylist: 2500, topStylist: 3000, seniorStylist: 4000, styleDirector: 5300, educationDirector: 6300, creativeDirector: 8000 },
    { title: "Boys Under 8", stylist: 1250, topStylist: 1500, seniorStylist: 2000, styleDirector: 2650, educationDirector: 3150, creativeDirector: 4000 },
    { title: "Styling", stylist: 800, topStylist: 900, seniorStylist: 1000, styleDirector: 1200, educationDirector: 1500 },
    { title: "Hair Wash", stylist: 600 },
    { title: "Toppik", stylist: "700-1200" },
    { title: "Men’s Grooming", stylist: 5000 },
    { title: "Men’s Grooming (with styling & beard/shave)", stylist: 7000 },
    { title: "Transformation", stylist: "15000-25000" },
  ];

  const technicalServices = [
    { title: "Highlights", startingPrice: "10000 – 25000" },
    { title: "Amonia Free Tint", startingPrice: 5000 },
    { title: "Own color Application", startingPrice: 2000 },
    { title: "Full Head Roots", technician: 4000, seniorTechnician: 5000 },
    { title: "Half Head Roots", technician: 2000, seniorTechnician: 2500 },
  ];

  const treatments = [
    { title: "Keratin Hair Strengthening", startingPrice: 15000 },
    { title: "Snapshot Hair Treatment", startingPrice: "3500/pkt" },
    { title: "Therapy Rejuvenating Treatment", startingPrice: 6000 },
    { title: "Protein Oxygenating Treatment", startingPrice: 6000 },
    { title: "Solution Hair Treatment", startingPrice: 5500 },
  ];

  const facials = [
    { title: "Aromatic Mask", price: 3500 },
    { title: "Brightening Mask", price: 3500 },
    { title: "Protein Radiance", price: 5500 },
    // Add more facials here
  ];

  const groomingPackages = [
    {
      name: "Package One",
      services: ["Haircut (Stylist)", "Shave", "Styling (Stylist)", "Protein Radiance Facial", "Makeup", "Mani – Pedi (Express)"],
      price: 18000,
    },
    {
      name: "Package Two",
      services: ["Haircut (Top Stylist)", "Shave", "Styling (Top Stylist)", "Hydradermie Facial", "Protein Hair Treatment", "Makeup", "Mani – Pedi (Executive)"],
      price: 28000,
    },
    // Add additional packages
  ];

  return (
    <div className="mx-10 my-20 lg:mx-40 xl:mx-60 flex flex-col">
      <h1 className="text-3xl font-bold text-center">Our Services</h1>

      {/* Hair Services */}
      <section className="mt-10">
        <h2 className="text-2xl font-semibold">Hair Services</h2>
        <table className="w-full mt-4 border border-gray-300 rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 text-left">Service</th>
              <th className="p-3 text-center">Stylist</th>
              <th className="p-3 text-center">Top Stylist</th>
              <th className="p-3 text-center">Senior Stylist</th>
              <th className="p-3 text-center">Style Director</th>
              <th className="p-3 text-center">Education Director</th>
              <th className="p-3 text-center">Creative Director</th>
            </tr>
          </thead>
          <tbody>
            {hairServices.map((service, index) => (
              <tr key={index} className="odd:bg-white even:bg-gray-50">
                <td className="p-3">{service.title}</td>
                <td className="p-3 text-center">{service.stylist}</td>
                <td className="p-3 text-center">{service.topStylist || "-"}</td>
                <td className="p-3 text-center">{service.seniorStylist || "-"}</td>
                <td className="p-3 text-center">{service.styleDirector || "-"}</td>
                <td className="p-3 text-center">{service.educationDirector || "-"}</td>
                <td className="p-3 text-center">{service.creativeDirector || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Technical Services */}
      <section className="mt-10">
        <h2 className="text-2xl font-semibold">Technical Services</h2>
        <table className="w-full mt-4 border border-gray-300 rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 text-left">Service</th>
              <th className="p-3 text-center">Starting Price</th>
            </tr>
          </thead>
          <tbody>
            {technicalServices.map((service, index) => (
              <tr key={index} className="odd:bg-white even:bg-gray-50">
                <td className="p-3">{service.title}</td>
                <td className="p-3 text-center">{service.startingPrice}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Treatments */}
      <section className="mt-10">
        <h2 className="text-2xl font-semibold">Hair & Scalp Treatments</h2>
        <table className="w-full mt-4 border border-gray-300 rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 text-left">Service</th>
              <th className="p-3 text-center">Starting Price</th>
            </tr>
          </thead>
          <tbody>
            {treatments.map((treatment, index) => (
              <tr key={index} className="odd:bg-white even:bg-gray-50">
                <td className="p-3">{treatment.title}</td>
                <td className="p-3 text-center">{treatment.startingPrice}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Facials */}
      <section className="mt-10">
        <h2 className="text-2xl font-semibold">Facials</h2>
        <table className="w-full mt-4 border border-gray-300 rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 text-left">Service</th>
              <th className="p-3 text-center">Price</th>
            </tr>
          </thead>
          <tbody>
            {facials.map((facial, index) => (
              <tr key={index} className="odd:bg-white even:bg-gray-50">
                <td className="p-3">{facial.title}</td>
                <td className="p-3 text-center">{facial.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Groom Packages */}
      <section className="mt-10">
        <h2 className="text-2xl font-semibold">Groom Packages</h2>
        {groomingPackages.map((pkg, index) => (
          <div key={index} className="bg-gray-100 p-4 mt-4 rounded-lg">
            <h3 className="text-xl font-bold">{pkg.name}</h3>
            <ul className="list-disc list-inside mt-2">
              {pkg.services.map((service, idx) => (
                <li key={idx}>{service}</li>
              ))}
            </ul>
            <p className="mt-2 font-semibold">Price: {pkg.price}</p>
          </div>
        ))}
      </section>
    </div>
  );
}
