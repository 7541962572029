import React from "react";

export default function TermsConditions() {
    return (
        <div className="mx-10 my-20 lg:mx-40 xl:mx-60 text-gray-800">
            <h1 className="text-3xl font-bold">Terms & Conditions</h1>
            <p className="mt-4">
                Welcome to Gents Saloon. By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions. If you disagree with any part of these terms, please do not use our website.
            </p>

            <h2 className="text-2xl font-semibold mt-6">1. General Terms</h2>
            <p className="mt-2">
                Gents Saloon provides grooming services, including haircuts, beard grooming, shaves, facials, and hair coloring. By booking our services, you agree to these terms and acknowledge that all information provided by you is accurate and up-to-date.
            </p>

            <h2 className="text-2xl font-semibold mt-6">2. Booking and Cancellation Policy</h2>
            <p className="mt-2">
                Appointments can be booked online, by phone, or in person. We request that any changes or cancellations be made at least 24 hours in advance. Cancellations made with less than 24 hours’ notice may be subject to a cancellation fee.
            </p>

            <h2 className="text-2xl font-semibold mt-6">3. Payments and Refunds</h2>
            <p className="mt-2">
                Payments for services are due at the time of service. We accept cash, credit cards, and digital payments. All sales are final, and no refunds are issued once a service has been provided. If you are unsatisfied with a service, please let us know within 48 hours, and we will make reasonable efforts to address your concerns.
            </p>

            <h2 className="text-2xl font-semibold mt-6">4. Privacy Policy</h2>
            <p className="mt-2">
                Please review our Privacy Policy to understand how we collect, use, and protect your personal information. By using our services, you consent to our collection and use of your data as outlined in our Privacy Policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6">5. User Responsibilities</h2>
            <p className="mt-2">
                You agree to provide accurate and complete information when booking appointments and to refrain from disruptive or inappropriate behavior while in our salon. We reserve the right to refuse service to anyone who violates these terms.
            </p>

            <h2 className="text-2xl font-semibold mt-6">6. Intellectual Property</h2>
            <p className="mt-2">
                All content on this website, including text, graphics, and logos, is the property of Gents Saloon or its content suppliers. Unauthorized use of our content is prohibited.
            </p>

            <h2 className="text-2xl font-semibold mt-6">7. Limitation of Liability</h2>
            <p className="mt-2">
                Gents Saloon is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services. We are not responsible for any allergic reactions or injuries caused by products used during services, though we take every precaution to ensure quality and safety.
            </p>

            <h2 className="text-2xl font-semibold mt-6">8. Changes to Terms & Conditions</h2>
            <p className="mt-2">
                Gents Saloon reserves the right to update or modify these terms and conditions at any time. Changes will be posted on our website, and it is your responsibility to review these terms regularly. Continued use of our services indicates acceptance of any changes.
            </p>

            <h2 className="text-2xl font-semibold mt-6">9. Governing Law</h2>
            <p className="mt-2">
                These terms are governed by the laws of Pakistan. Any disputes arising from these terms or your use of our services will be resolved in the courts of Pakistan.
            </p>

            <p className="mt-8">
                If you have any questions about these Terms & Conditions, please contact us at <a href="mailto:INFO@GENTSSALOON.PK" className="text-[#8d59dc] hover:underline">INFO@GENTSSALOON.PK</a>.
            </p>
        </div>
    );
}
