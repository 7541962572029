import React, { useState } from "react";

export default function Courses() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [formData, setFormData] = useState({ name: "", email: "", phone: "" });

    const courses = [
        { title: "Barbering Basics", description: "Learn the fundamentals of barbering.", img: "/img/barberBasic.jpg" },
        { title: "Advanced Hair Styling", description: "Master advanced styling techniques.", img: "/img/haircutting.jpg" },
        { title: "Beard Grooming Techniques", description: "Detailed beard grooming techniques.", img: "/img/bread.avif" },
    ];

    const openModal = (course) => {
        setSelectedCourse(course);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedCourse(null);
        setFormData({ name: "", email: "", phone: "" });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const message = `Hello! I'm interested in enrolling in the ${selectedCourse?.title} course. Here are my details:\n\nName: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}`;
        const phoneNumber = "923287787787";
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, "_blank");
        closeModal();
    };

    return (
        <div className="mx-10 mt-20 lg:mx-40 xl:mx-60 flex flex-col justify-center">
            <h1 className="block py-5 text-3xl font-bold text-center">Training & Courses</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
                {courses.map((course, index) => (
                    <div key={index} className="bg-white rounded-lg shadow-lg p-4">
                        <img src={course.img} alt={course.title} className="rounded-lg h-40 w-full object-cover" />
                        <h2 className="text-xl font-bold mt-4">{course.title}</h2>
                        <p className="mt-2">{course.description}</p>
                        <button
                            className="mt-4 bg-[#8d59dc] text-white py-2 px-4 rounded transition hover:bg-[#7b4bc5]"
                            onClick={() => openModal(course)}
                        >
                            Admission Now
                        </button>
                    </div>
                ))}
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto">
                        <h2 className="text-2xl font-bold mb-4 text-center">Admission Form</h2>
                        <p className="text-lg font-semibold mb-2">Course: {selectedCourse?.title}</p>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium">Full Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#8d59dc] focus:border-[#8d59dc]"
                                    placeholder="Your name"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#8d59dc] focus:border-[#8d59dc]"
                                    placeholder="you@example.com"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Phone Number</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#8d59dc] focus:border-[#8d59dc]"
                                    placeholder="Your phone number"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-[#8d59dc] text-white py-2 px-4 rounded transition hover:bg-[#7b4bc5]"
                            >
                                Submit
                            </button>
                        </form>
                        <button
                            className="mt-4 text-red-600 hover:text-red-800 text-center w-full"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
