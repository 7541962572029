import React, { useEffect, useState, useRef } from "react";

export default function Header() {
  const [small, setSmall] = useState(true);
  const [base, setBase] = useState(false);
  const [border, setBorder] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(0);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setSmall(window.pageYOffset <= 300);
        setBorder(window.pageYOffset > 700);
        if (window.pageYOffset > 300 && window.pageYOffset < 800) {
          setBase(true);
          setSmall(false);
          setOffset(window.pageYOffset / 70);
        } else if (window.pageYOffset <= 300) {
          setBase(false);
          setSmall(true);
        }
      });
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    }
  }, []);

  const Links = [
    { name: "Home", link: "/#home" },
    { name: "Our Services", link: "/#services" },
    { name: "Our Courses", link: "/courses" },
    { name: "About Us", link: "/#about" },
    { name: "Contact", link: "/#contact" },
  ];

  const showlinks = (e) => {
    const navMenu2 = document.querySelector("#navMenu2");
    const hamburger = document.querySelector("#hamburger");
    const close = document.querySelector("#close");

    if (hamburger.classList.contains("hidden")) {
      hamburger.classList.remove("hidden");
    } else {
      close.classList.remove("hidden");
    }

    e.currentTarget.classList.add("hidden");

    if (navMenu2.classList.contains("hidden")) {
      navMenu2.classList.remove("hidden");
      navMenu2.classList.add("flex");
    } else {
      navMenu2.classList.add("hidden");
      navMenu2.classList.remove("flex");
    }
  };

  return (
    <>
      <nav
        style={{
          marginLeft: base ? `${offset + "px"}` : "0px",
          marginTop: base ? `${offset + "px"}` : "0px",
          width: base ? `${width - offset * 4 + "px"}` : "",
          paddingLeft: base ? `${offset * 2 + "px"}` : "0px",
          paddingRight: base ? `${offset * 2 + "px"}` : "0px",
        }}
        className={`${small ? "relative w-full z-20" : ""} ${base ? "fixed z-20" : ""
          }`}
      >


        <div
          style={{
            borderRadius: base ? `${offset * 4 + "px"}` : "",
            paddingBottom: base ? `${offset * 1 + "px"}` : "",
            paddingTop: base ? `${offset * 1 + "px"}` : "",
          }}
          className={`bg-white text-black px-10 sm:px-20 py-2 sm:py-4 flex justify-between items-center ${border ? "border-4 border-black" : ""
            }`}
        >
          <a href="/" className="text-lg text-[#ed696a]">
            <img src="/img/logo.png" className="lg:w-[200px] w-[140px]" />
          </a>
          <div className="md:hidden my-auto">
            <button
              id="hamburger"
              onClick={showlinks}
              className="w-9 h-10 lg:hidden"
            >
              <div className="border border-black mb-4"></div>
              <div className="border border-black"></div>
            </button>
            <button
              id="close"
              onClick={showlinks}
              className="hidden w-9 h-10 lg:hidden relative"
            >
              <div className="border border-black absolute w-full transition ease-in-out duration-500 rotate-45"></div>
              <div className="border border-black absolute w-full transition ease-in-out duration-500 -rotate-45"></div>
            </button>
          </div>
          <div id="navMenu" className="hidden md:block min-w-max">
            <ul className="md:flex items-center justify-between space-x-3 lg:space-x-5 xl:w-[40rem] max-w-[40rem] lg:font-semibold">
              <li className="hidden"></li>
              {Links.map((link) => (
                <li key={link.name} className="group h-5">
                  <a
                    className="cursor-pointer"
                    href={link.link}
                    smooth={true}
                  >
                    {link.name}
                  </a>
                  <div className="w-0 border-[#ed696a] transition-[width] ease-in duration-500 group-hover:w-full group-hover:border-2"></div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          id="navMenu2"
          className="hidden flex-col items-center bg-[#35353ff2] rounded-[20px] m-2 py-8 min-w-max"
        >
          <ul className="md:hidden text-2xl font-semibold text-center">
            <li className="hidden"></li>
            {Links.map((link) => (
              <li key={link.name} className="m-6">
                <a
                  onClick={() => document.querySelector("#close").click()}
                  className="cursor-pointer"
                  href={link.link}
                  smooth={true}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
}
